<template>
  <div>
    <div class="row">
      <div class="col">
        <sidebar-filter ref="sidebar" :show-is-disabled="true" />
        <data-table
          v-if="siteId"
          model="sport/SiteTournament"
          service-file="SiteTournamentsService"
          :path="path()"
          :columns="columns()"
          :column-templates="columnTemplates()"
          :enableRowClick="false"
          :show-create="false"
          :show-remove="false"
          :customActionButton="{
            action: showSorting,
            title: 'Sort tournaments',
            icon: 'uil uil-sort',
          }"
          ref="list"
          :extraComponentHeight="sidebarHeight"
        />
      </div>
      <sortable-table
        ref="sortable"
        :table-fields="sortFields()"
        title="Sort enabled tournaments"
        service-file="SiteTournamentsService"
        @refresh="$refs.list.search()"
      />
    </div>
  </div>
</template>

<script lang="jsx">
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import SiteTournamentsService from '@services/SiteTournamentsService'
import SidebarFilter from '@molecules/sports/sports-filter'
import SortOrderColumn from '@/components/table/sort-order-column'
import SortableTable from '@molecules/sports/sortable-table.vue'

export default {
  created() {
    this.setTitle()
    this.applyFilters()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data(){
    return {
      sidebarHeight: 0,
    }
  },
  components: { SidebarFilter, SortOrderColumn, SortableTable },
  computed: {
    siteId() {
      return this.$store.state.sidebar.siteId
    },
    sportId() {
      return this.$store.state.sidebar.sportId
    },
    showDisabled() {
      return this.$store.state.sidebar.showDisabled
    },
    marketId() {
      return this.$store.state.sidebar.market
    }
  },
  watch: {
    siteId() {
      this.applyFilters()
    },
    sportId() {
      this.applyFilters()
    },
    showDisabled() {
      this.applyFilters()
    },
    marketId() {
      this.applyFilters()
    }
  },
  methods: {
    path() {
      return `hercules/sports/site-tournaments?${this.buildQueryString(
        Object.assign(
          {
            with: ['tournament.country,tournament.sport,market'].join(',')
          },
          this.getFilters()
        )
      )}`
    },
    columns() {
      return [
        {
          field: 'tournament.name',
          type: 'text',
          search_key: 'tournament__name',
          order_key: 'tournament__name',
          label: 'Tournament',
          filterable: true,
        },
        { field: 'tournament.sport.name', type: 'text', filterable: true, label: 'Sport' },
        {
          field: 'tournament.gender',
          type: 'text',
          filterable: true,
          search_key: 'tournament__gender',
          order_key: 'tournament__gender',
          label: 'Gender',
        },
        {
          field: 'tournament.country.name',
          search_key: 'tournament__country__name',
          order_key: 'tournament__country__name',
          label: 'Country',
          filterable: true,
        },
        { field: 'market.label', search_key: 'market.label', label: 'Market', type: 'text', filterable: true },
        { field: 'enabled', type: 'text', filterable: true },
        { field: 'featured', type: 'text', filterable: true },
        { field: 'restricted', type: 'text', filterable: true },
        { field: 'sort_order', type: 'text', filterable: true },
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.list.isLoading = true
      let object = { id: id }
      object[field] = value
      SiteTournamentsService.update(object)
        .then(() => {
          this.$refs.list.search()
          this.showSuccessMessage('Column updated')
        })
        .catch(() => {
          this.showErrorMessage('Failed to update tournament')
          this.$refs.list.isLoading = false
        })
    },
    columnTemplates() {
      const self = this
      return {
        enabled: function (row) {
          return (
            <EnabledActionColumn
              row={row}
              field={'enabled'}
              onColumnClick={self.actionColumnClick}
              confirmTitle={'Careful, this is a destructive action!'}
              confirmMessage={
                row.enabled ? 'All related event pages (and their content) will be deleted.' : null
              }
            />
          )
        },
        featured: function (row) {
          return <EnabledActionColumn row={row} field={'featured'} onColumnClick={self.actionColumnClick} />
        },
        restricted: function (row) {
          return <EnabledActionColumn row={row} field={'restricted'} onColumnClick={self.actionColumnClick} />
        },
      }
    },
    showSorting() {
      if (!this.sportId) {
        return this.showErrorMessage('Select a sport')
      }
      SiteTournamentsService.get({
        site_id: this.siteId,
        enabled: 1,
        market_id: this.marketId,
        tournament__sport__id: this.sportId,
        with: 'tournament,tournament.country,market'
      })
        .then((response) => {
          if (response.data.success) {
            this.$refs.sortable.show(response.data.result)
          } else {
            this.showErrorMessage('Failed to fetch providers')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    sortFields() {
      return [
        { name: 'tournament.name', label: 'Tournament' },
        { name: 'tournament.gender', label: 'Gender' },
        { name: 'tournament.country.name', label: 'Country' },
      ]
    },
    getFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      if (this.sportId) filters['tournament__sport__id'] = this.sportId
      if (this.showDisabled === false) filters['enabled'] = 1
 
      return filters
    },
    applyFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      if (this.sportId) filters['tournament__sport__id'] = this.sportId
      if (this.showDisabled === false) filters['enabled'] = 1
      if (this.marketId) filters['market_id'] = this.marketId

      this.emitter.emit('data-table.applyFilters', filters)
    }
  }
}
</script>
