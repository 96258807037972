<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-30">
        <label class="date-label" for="date">Date</label>
        <VueDatePicker 
          v-model="date" 
          range
          :clearable="false" 
          cancel-text="Close"
          input-class-name="dp-custom-input"
          position="left"
          :disabled-dates="disabledDates"
        />
        <p class="extra-date-info" v-if="extraDateInfo">{{ extraDateInfo }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps, onMounted } from 'vue';
import moment from 'moment';

const emits = defineEmits('filterChanged');
const props = defineProps({
  extraDateInfo: {
    type: String,
    required: false,
    default: ''
  },
  isGroupedMonitoring: {
    type: Boolean,
    required: false,
    default: false
  }
});

const yesterday = ref(moment().subtract(1, 'days').format('YYYY-MM-DD') + 'T00:00' + ' - ' + moment().subtract(1, 'days').format('YYYY-MM-DD') + 'T23:59');
const date = ref('');
const filters = computed(() => ({ date: date.value }));

watch(filters, () => {
  emits('filterChanged', getFilter());
});

const getFilter = () => {
  let filter = {};
  if (date.value && date.value.length >= 2) {
    const [startDate, endDate] = date.value.map(d => moment(d).format('YYYY-MM-DDTHH:mm'));

    filter.created_start = startDate;
    filter.created_end = endDate || startDate;
  }

  return filter;
}

const disabledDates = computed(() => {
  const today = new Date();
  return (date) => date > today;
});

onMounted(() => {
  if (props.isGroupedMonitoring) {
    const dates = yesterday.value.split(' - ').map(d => new Date(d));
    date.value = dates;
  }
});

</script>

<style>
.dp__main {
  min-width: 380px !important;
}
.type > li.active {
  margin-top: 0 !important;
}
.extra-date-info {
  margin-top: 12px;
  font-size: 12px;
  color: #696969;
}
.time-label {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #696969;
}
.dp-custom-input {
  background-color: #F1F2F9 !important;
  border-color: #F1F2F9 !important;
  border-radius: 4px !important;
}
.dp__action_row{
  flex-direction: column !important;
}
.dp__selection_preview{
  max-width: fit-content !important;
  margin-bottom: 8px;
}
.date-label {
  color: #39394f;
  font-weight: 600;
  margin-bottom: 8px;
  display: inline-block;
}
</style>
