<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="edit"
      :inCard="true"
      :formFields="structure.list"
      :path="config.url"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :on-remove="remove"
    />
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import Affiliate from '@models/Affiliate'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import AffiliatesService from '@services/AffiliatesService'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { DynamicForm, ConfirmDialog },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Affiliate.getConfig(),
    }
  },
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } General`
    ),
      this.getAffiliate()
  },
  methods: {
    getAffiliate() {
      AffiliatesService.getOne(this.$route.params['id'], { with: 'default_market,unit' }).then((response) => {
        this.object = response.data.result[0]
        this.structure = Affiliate.getStructure()
      })
    },
    submit() {
      const self = this
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return AffiliatesService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getAffiliate()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => (self.$refs.form.isLoading = false))
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        AffiliatesService.remove(this.$route.params['id'])
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/operators')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
