<script>
import TableModalForm from '@organisms/table/table-modal-form.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import ConfirmGranularDialog from '@atoms/misc/confirm-granular-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import _ from 'lodash'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

const confirmGranularDelete = async (title, content, granular_id) => {
  return await openDialog(ConfirmGranularDialog, { title, content, granular_id })
}

const confirmSubmit = async (title, content, actionButtonTitle, actionButtonIconClass, actionButtonClass) => {
  return await openDialog(ConfirmDialog, {
    title,
    content,
    actionButtonTitle,
    actionButtonIconClass,
    actionButtonClass,
  })
}

export default {
  extends: TableModalForm,
  data() {
    return {
      formType: 'edit',
    }
  },
  methods: {
    async show(id) {
      this.clear()
      const model = await this.modelLoader()
      const service = await this.serviceLoader()
      service.default
        .getOne(id, { with: this.config.editWithData })
        .then(async (response) => {
          if (response.data.result === undefined || response.data.result?.length === 0) {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showErrorMessage('No results were found.')
            }
          } else {
            this.object = response.data.result[0]
            let extraFieldNotifyId = this.notifyExtraFieldIdEdit
              ? this.object[this.notifyExtraFieldIdEdit]
              : id

            if (typeof model.default.getExtraFieldsValues !== 'undefined') {
              this.extraFields = await model.default.getExtraFieldsValues(extraFieldNotifyId)

              if (this.extraFields.length > 0) {
                this.extraFields.forEach((extraField) => {
                  this.extraFieldsObject[extraField.name] = extraField.value
                })
              }
            }
            this.loadStructure(() => {
              this.showModal()
            })
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    async submit() {
      if (
        this.granular
          ? await confirmSubmit(
              this.extraEditInfo.title,
              this.extraEditInfo.message,
              'Submit',
              'uil uil-check',
              'btn btn-primary'
            )
          : true
      ) {
        this.$refs.form.isLoading = true
        this.object = _.omit(this.object, this.editFieldFilters)
        this.object = _.assign(this.object, this.createDefaults)
        if (this.submitDataModifier) {
          this.object = this.submitDataModifier(this.object)
        }

        const service = await this.serviceLoader()
        this.$refs.form
          .process(this.object)
          .then((response) => {
            return service.default.update(response)
          })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} updated`)
              if (this.checkStatusOfOperators) {
                this.checkStatusOfOperators(this.object)
              }
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(() => this.showUnknownErrorMessage)
          .finally(() => {
            if (this.$refs.form) {
              this.$refs.form.isLoading = false
            }
          })
      } else {
        this.$refs.form.isLoading = false
      }
    },
    async remove() {
      if (
        this.granular
          ? await confirmGranularDelete(
              this.extraDeleteInfo.title,
              this.extraDeleteInfo.message,
              this.object.id
            )
          : await confirmDelete(
              'Warning',
              `Are you sure you want to delete this item? ${this.extraDeleteInfo.message}`
            )
      ) {
        this.serviceLoader()
          .then((service) => {
            return service.default.remove({ id: this.object.id })
          })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
  unmounted() {
    this.$store.commit('data-table/setInitFormObject', {})
  },
}
</script>
