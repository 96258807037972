<template>
  <div class="card">
    <dynamic-form
      ref="form"
      :inCard="true"
      form-type="edit"
      :formFields="structure.list"
      :path="config.url"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :on-remove="remove"
      pusherModel="sites-banners"
    />
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import Banner from '@models/cms/Banner'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import BannersService from '@services/cms/BannersService'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { ConfirmDialog, DynamicForm },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Banner.getConfig(),
    }
  },
  mounted() {
    this.getSiteBanner()
  },
  methods: {
    getSiteBanner() {
      BannersService.getOne(this.$route.params['id'], {
        with: 'site,market',
      }).then((response) => {
        this.object = response.data.result[0]
        this.setTitle(`${this.object.name}: General`)
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = Banner.getStructure()
    },
    submit() {
      const self = this
      delete this.object.status_changed_by
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return BannersService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getSiteBanner()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        BannersService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/site-banners')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
