<template>
  <div>
    <div class="row">
      <div class="col">
        <sidebar-filter ref="sidebar" :show-is-past-events="true" :show-date="false" />
        <data-table
          v-if="siteId"
          ref="list"
          model="cms/Template"
          service-file="TemplatesService"
          :path="path()"
          :columns="columns()"
          :show-remove="false"
          :show-create="false"
          :rowActionButtons="rowActions()"
          :enableRowClick="false"
          :column-templates="columnTemplates()"
          :customActionButton="{
            action: createSportPages,
            title: 'Refresh event pages',
            class: 'btn btn-primary',
            icon: 'uil uil-refresh',
          }"
          :customSecondaryButton="{
            action: showSorting,
            title: 'Sort events',
            icon: 'uil uil-sort',
          }"
          :extraComponentHeight="sidebarHeight"
        />
      </div>
      <sortable-table
        ref="sortable"
        :table-fields="sortFields()"
        title="Sort showcased events"
        service-file="SiteEventsService"
        @refresh="$refs.list.search()"
      />
    </div>
  </div>
</template>

<script lang="jsx">
import PagesService from '@services/PagesService'
import SidebarFilter from '@molecules/sports/sports-filter'
import SiteEventsService from '@services/SiteEventsService'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import SortOrderColumn from '@/components/table/sort-order-column'
import SortableTable from '@molecules/sports/sortable-table.vue'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data(){
    return {
      sidebarHeight: 0,
    }
  },
  showPastEvents() {
    return this.$store.state.sidebar.showPastEvents
  },
  computed: {
    siteId() {
      return this.$store.state.sidebar.siteId
    },
    sportId() {
      return this.$store.state.sidebar.sportId
    },
    date() {
      return this.$store.state.sidebar.date
    },
    showPastEvents() {
      return this.$store.state.sidebar.showPastEvents
    },
    marketId() {
      return this.$store.state.sidebar.market
    }
  },
  watch: {
    siteId() {
      this.applyFilters()
    },
    sportId() {
      this.applyFilters()
    },
    date() {
      this.applyFilters()
    },
    showPastEvents() {
      this.applyFilters()
    },
    marketId() {
      this.applyFilters()
    }
  },
  components: { SidebarFilter, SortOrderColumn, SortableTable },
  methods: {
    path() {
      return `hercules/sports/site-events?${this.buildQueryString(
        Object.assign({ 
          with: ['providers,event.tournament,event.tournament.country,sport,event.home_team,event.away_team,event.sport,site,market'].join(','),
          orderBy: 'event__datetime', ascending: '1', hide_past_events: this.showPastEvents === true ? 0 : 1
        }, this.getFilters())
      )}`
    },
    columns() {
      return [
        { field: 'event.sport.name', type: 'text', search_key: 'sport', label: 'Sport', filterable: true },
        {
          field: 'event.tournament.country.code',
          search_key: 'event__tournament__country__code',
          order_key: 'event__tournament__country__code',
          type: 'text',
          label: 'Country',
          filterable: true,
        },
        {
          field: 'event.tournament.name',
          search_key: 'event__tournament__name',
          order_key: 'event__tournament__name',
          type: 'text',
          filterable: true,
          label: 'Tournament',
        },
        { field: 'market.label', label: 'Market', type: 'text', filterable: true },
        {
          field: 'event.name',
          search_key: 'event__name',
          order_key: 'event__name',
          type: 'text',
          label: 'Participants',
          filterable: true,
        },
        {
          field: 'event.date',
          search_key: 'event__date',
          order_key: 'event__date',
          type: 'text',
          filterable: true,
          label: 'Date',
        },
        {
          field: 'event.time',
          type: 'text',
          search_key: 'event__time',
          order_key: 'event__time',
          filterable: true,
          label: 'Time',
        },
        {
          field: 'restricted',
          type: 'text',
          filterable: true,
        },
        {
          field: 'featured',
          type: 'text',
          filterable: true,
        },
        { field: 'showcased', type: 'text', filterable: true },
        {
          field: 'event.status',
          search_key: 'event__status',
          order_key: 'event__status',
          template: TableColumnTemplate.EVENT_STATUS,
          label: 'Status',
          type: 'text',
          filterable: true,
        },
        {
          field: 'providers',
          type: 'text',
          filterable: false,
          sortable: false,
        },
      ]
    },
    columnTemplates() {
      const self = this
      return {
        featured: function (row) {
          return <EnabledActionColumn row={row} field={'featured'} onColumnClick={self.actionColumnClick} />
        },
        'event.time': function (row) {
          return row.event.time.substr(0, 5)
        },
        'event.tournament.country.code': function (row) {
          if (row.event.tournament.country.code === 'international') {
            return 'INT'
          }
          return this.upperCase(row.event.tournament.country.code)
        },
        restricted: function (row) {
          return <EnabledActionColumn row={row} field={'restricted'} onColumnClick={self.actionColumnClick} />
        },
        showcased: function (row) {
          return <EnabledActionColumn row={row} field={'showcased'} onColumnClick={self.actionColumnClick} />
        },
      }
    },
    rowActions() {
      return [
        {
          action: this.editPage,
          title: 'Edit page',
          icon: 'uil uil-file',
          class: 'btn-info',
        },
      ]
    },
    editPage(row) {
      PagesService.get({ type: 'event', relation_id: row.id })
        .then((response) => {
          if (response.data.result.length === 1) {
            this.$router.push(`site-pages/${response.data.result[0].id}`)
          } else {
            this.showErrorMessage('Page not found')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    createSportPages() {
      this.$refs.list.isLoading = true
      this.$refs.list.setCustomActionButtonLoading(true)
      PagesService.rebuildSportsPage({
        site_id: this.siteId,
        market_id: this.marketId,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Event pages refreshed')
          }
        })
        .catch(() => {
          this.showErrorMessage('Failed to create event pages')
        })
        .finally(() => {
          this.$refs.list.isLoading = false
          this.$refs.list.setCustomActionButtonLoading(false)
          this.$refs.list.search()
        })
    },
    showSorting() {
      if (!this.sportId) {
        return this.showErrorMessage('Select a sport')
      }
      SiteEventsService.get({
        site_id: this.siteId,
        market_id: this.marketId,
        event__sport__id: this.sportId,
        showcased: 1,
        with: 'event.tournament,event.tournament.country',
      })
        .then((response) => {
          if (response.data.success) {
            this.$refs.sortable.show(response.data.result)
          } else {
            this.showErrorMessage('Failed to fetch tournament')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    sortFields() {
      return [
        { name: 'event.tournament.country.name', label: 'Country' },
        { name: 'event.tournament.name', label: 'Tournament' },
        { name: 'event.name', label: 'Participants' },
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.list.isLoading = true
      let object = { id: id }
      object[field] = value
      SiteEventsService.update(object)
        .then((response) => {
          if (response.data.success) {
            this.showSuccessMessage(`Column updated`)
            this.$refs.list.search()
          } else {
            this.showErrorMessage(response.data.messages[0])
            this.$refs.list.isLoading = false
          }
        })
        .catch(() => {
          this.showErrorMessage('Failed to update tournament')
          this.$refs.list.isLoading = false
        })
    },
    applyFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      if (this.sportId) filters['event__sport__id'] = this.sportId
      if (this.date) filters['event__date'] = this.date
      if (this.marketId) filters['market_id'] = this.marketId
      if (this.showPastEvents === true) filters['hide_past_events'] = 0
      this.emitter.emit('data-table.applyFilters', filters)
    },
    getFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      if (this.sportId) filters['event__sport__id'] = this.sportId
      if (this.date) filters['event__date'] = this.date
      if (this.marketId) filters['market_id'] = this.marketId
      if (this.showPastEvents === true) filters['hide_past_events'] = 0

      return filters
    },
  },
}
</script>
