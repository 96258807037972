import { createRouter, createWebHistory } from 'vue-router'

import Auth from './auth'
import Logs from './logs'
import Misc from './misc'
import General from './general'
import Testing from './testing'
import Cms from './cms'
import Devops from './devops'
import Admin from './admin'
import Pandora from './pandora'
// import Templates from './templates'
import Dms from './dms'
import Sports from './sports'
import Koala from './koala'
import Widgets from './widgets'
import Reports from './reports'

// import the store
import { store } from '../store/index'

const tableModuleNames = [
  'data-table',
  'hreflang-table',
  'custom-hreflang-table',
  'model-select-items-selected',
  'model-select-items-selector',
]

export const routes = [
  ...Auth,
  ...Logs,
  ...Misc,
  ...General,
  ...Devops,
  ...Admin,
  // ...Templates,
  ...Dms,
  ...Sports,
  ...Koala,
  ...Cms,
  ...Widgets,
  ...Testing,
  ...Reports,
  ...Pandora,
  {
    path: '/:notFound(.*)',
    name: 'errorPage',
    meta: {
      auth: true,
      name: 'Page not found',
    },
    component: () => import('../components/atomic/pages/misc/errorPage.vue'),
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  // let localFavorites = JSON.parse(localStorage.getItem('favorites'))
  if (to.meta.name !== 'Dashboard' && to.meta.name !== 'Page not found') {
    store.commit('sidebar/setSelectedSidebarItem', to.meta.parent)
    if (to.meta.auth && !to.params?.id) {
      store.commit('sidebar/setRecentlyVisited', { parent: to.meta.parent, to: to.path })
    }

    const fromBaseRoute = from.path.split('/')[1]
    const toBaseRoute = to.path.split('/')[1]

    if (from.params.id && to.params.id) {
      tableModuleNames.forEach((moduleName) => {
        if (store.hasModule(moduleName)) {
          store.dispatch(`${moduleName}/clearQueries`)
        }
      })
    }

    if (store.hasModule('data-table')) {
      if (fromBaseRoute !== toBaseRoute) {
        store.dispatch('data-table/clearQueries')
        store.dispatch('data-table/setMemorizedQueries', false)
      }

      if (!from.params.id && to.params.id) {
        store.dispatch('data-table/setMemorizedQueries', true)
        store.dispatch('data-table/clearQueries')
      } else if (from.params.id && !to.params.id) {
        store.dispatch('data-table/setQueriesFromMemorized')
      }
    }
  }
  next()
})
