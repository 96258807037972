<template>
  <div class="card">
    <dynamic-form
      ref="form"
      :inCard="true"
      form-type="edit"
      :formFields="structure.list"
      :path="config.url"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :on-remove="remove"
      pusherModel="sites-authors"
    />
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import Author from '@models/cms/Author'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import AuthorsService from '@services/AuthorsService'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { ConfirmDialog, DynamicForm },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Author.getConfig(),
    }
  },
  mounted() {
    this.getAuthor()
  },
  methods: {
    getAuthor() {
      AuthorsService.getOne(this.$route.params['id']).then((response) => {
        this.object = response.data.result[0]
        this.setTitle(`${this.object.name}: General`)
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = Author.getStructure()
    },
    submit() {
      AuthorsService.update(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.object = response.data.result
            this.showSuccessMessage(`Author updated`)
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        AuthorsService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/site-authors')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
