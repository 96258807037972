/* eslint-disable no-unused-vars */
import App from '@/main.js'
export default {
  methods: {
    beforeUnLoad(e) {
      if (App.$cookies.isKey('formIsDirty') && App.$cookies.get('formIsDirty') == 'true') {
        e.preventDefault()
        e.returnValue = 'You have unfinished changes!'
      }
    },
    removeBeforeUnloadEvent() {
      let formElements = document.getElementsByClassName('form-changed')
      for (let formElement of formElements) {
        formElement.classList.remove('form-changed')
      }
      window.removeEventListener('beforeunload', this.beforeUnLoad)
      App.$cookies.set('formIsDirty', false)
    },
  },
}
