<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: '/site-widgets-toplist' }"
            data-tooltip="Back to widgets"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-2 ml-2"> {{ widgetsList.title }} </span>
          <span class="text-muted mr-3"> {{ widgetsList.short_code }} </span>
          <span class="badge bg-purple ml-2" v-if="widgetsList.geo_located"> GEO LOCATED </span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(widgetsList.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/site-widgets-toplist/${widgetsList.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link
        tag="li"
        v-if="!widgetsList.geo_located"
        :to="{ path: `/site-widgets-toplist/${widgetsList.id}/items` }"
      >
        <a>Toplists</a>
      </router-link>
      <router-link
        tag="li"
        v-if="widgetsList.geo_located"
        :to="{ path: `/site-widgets-toplist/${widgetsList.id}/geo-items` }"
      >
        <a>Toplist Geolocation</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import WidgetsToplistService from '@services/WidgetsToplistService'

export default {
  created() {
    if (this.isIdMismatch) {
      this.getTopList()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
    this.title = `${this.widgetsList?.title}`
  },
  computed: {
    widgetsList() {
      return { ...this.$store.state.editPage.model }
    },
    isIdMismatch() {
      return Number(this.$route.params['id']) !== this.widgetsList?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  data() {
    return {
      title: '',
    }
  },
  methods: {
    async getTopList() {
      await WidgetsToplistService.getOne(this.$route.params['id'], { with: 'market' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = `${this.widgetsList?.title}`
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
