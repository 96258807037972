<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2 align-items-end">
      <div class="w-50" v-if="showSite">
        <span class="text-muted font-weight-semi-bold text-uppercase ml-2 mb-1 font-size-sm">Site</span>
        <base-select
          v-model="site"
          path="hercules/sites/sites?type[]=rage_ppc&type[]=rage_seo&type[]=sports&orderBy=name&ascending=1"
          ref="siteSelect"
          option-label-key="name"
          class="pr-2 pl-2 mb-0"
          :select-first-after-fetch="!(cacheSettings && cacheSettings.sports_site_id)"
          @update:modelValue="changeSiteId"
        />
      </div>
      <div class="w-50" v-if="showMarkets">
        <span class="text-muted font-weight-semi-bold text-uppercase ml-2 mb-1 font-size-sm">Market</span>
          <base-select
              v-model="market"
              trackBy="market_id"
              option-label-key="market.label"
              :path="`hercules/sites/site-markets?with=site,language,market&site_id=${site}`"
              ref="marketSelect" 
              class="pr-2 pl-2 mb-0"
              :select-first-after-fetch="!(cacheSettings && cacheSettings.sports_market_id)"
              :clearable="true"
            />
      </div>
      <div class="w-50" v-if="showSports">
        <div class="d-flex justify-content-between">
          <span class="text-muted font-weight-semi-bold text-uppercase ml-2 font-size-sm">
            Sport
          </span>
          <span class="text-muted mr-2 clickable" v-if="showSportClear" @click="clearSport">clear</span>
        </div>
        <base-select
        v-model="sport"
        path="hercules/sports/sports"
        ref="sportSelect"
        option-label-key="name"
        class="pr-2 pl-2 mb-0"
        />
      </div>

      <div class="w-50" v-if="showIsDisabled">
        <base-checkbox
          label="Show disabled"
          v-model="showDisabled"
          ref="disabledSelect"
          :switch="true"
          class="pr-3 pl-3 mb-0"
        />
      </div>

      <div class="w-50" v-if="showIsPastEvents">
        <base-checkbox
          label="Show past events"
          v-model="showPastEvents"
          ref="pastEventsSelect"
          :switch="true"
          class="pr-3 pl-3 mb-0"
        />
      </div>
      <div class="w-50" v-if="showDate">
        <span class="text-muted font-weight-semi-bold text-uppercase ml-2 mb-1 font-size-sm">Date</span>
        <base-select
          :clearable="true"
          v-model="date"
          option-label-key="label"
          :custom-options="getDateOptions()"
          class="pr-2 pl-2 mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import moment from 'moment'

export default {
  components: { BaseSelect, BaseCheckbox },
  mounted() {
    if (this.cacheConfig) {
      this.$nextTick(() => {
        this.site = this.cacheSettings.sports_site_id || null
        this.market = this.cacheSettings.sports_market_id || null
      })
    }
  },
  data() {
    return {
      cacheSettings: this.$cookies.get('pages_filter'),
      mounted: false, // to prevent multiple request after mount
      collapsed: false
    }
  },
  props: {
    portal: {
      type: String,
      default: () => 'filter-sidebar-sports'
    },
    showSports: {
      type: Boolean,
      default: true
    },
    showSite: {
      type: Boolean,
      default: true
    },
    showDate: {
      type: Boolean,
      default: false
    },
    showIsDisabled: {
      type: Boolean,
      default: false
    },
    showIsPastEvents: {
      type: Boolean,
      default: false
    },
    sportClearable: {
      type: Boolean,
      default: true
    },
    showMarkets: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    showSportClear() {
      return this.sportClearable && this.sport
    },
    site: {
      get() {
        return this.$store.state.sidebar.siteId
      },
      set(site) {
        this.$store.dispatch('sidebar/setSite', site)
        this.$cookies.set('pages_filter', {
          sports_site_id: site,
          site_id: site,
        })
      }
    },
    market: {
      get() {
        return this.$store.state.sidebar.market
      },
      set(market) {
        this.$store.dispatch('sidebar/setMarket', market)
        this.$cookies.set('pages_filter', {
          sports_market_id: market,
          market_id: market,
        })
      }
    },
    showDisabled: {
      get() {
        return this.$store.state.sidebar.showDisabled
      },
      set(showDisabled) {
        this.$store.dispatch('sidebar/setShowDisabled', showDisabled)
      }
    },
    sport: {
      get() {
        return this.$store.state.sidebar.sportId
      },
      set(sport) {
        this.$store.dispatch('sidebar/setSport', sport)
      }
    },
    date: {
      get() {
        return this.$store.state.sidebar.date
      },
      set(date) {
        this.$store.dispatch('sidebar/setDate', date)
      }
    },
    showPastEvents: {
      get() {
        return this.$store.state.sidebar.showPastEvents
      },
      set(showPastEvents) {
        this.$store.dispatch('sidebar/setShowPastEvents', showPastEvents)
      }
    },
    cacheConfig() {
      return this.cacheSettings && this.cacheSettings.site_id && this.cacheSettings.market_id && this.cacheSettings
    },
  },
  methods: {
    changeSiteId() {
      this.$store.dispatch('sidebar/setMarket', null)
      this.$nextTick(() => {
        this.$refs.marketSelect?.getOptions()
      })
    },
    clearSport() {
      this.sport = null
    },
    collapseClick() {
      this.collapsed = !this.collapsed
    },
    getDateOptions() {
      return [
        {
          id: new moment().format('YYYY-MM-DD'),
          label: 'Today'
        },
        {
          id: new moment().add(1, 'day').format('YYYY-MM-DD'),
          label: 'Tomorrow'
        }
      ]
    }
  }
}
</script>
