<template>
  <div>
    <new-version-notification />
    <search v-if="showSearch" @close="() => (showSearch = false)" />
    <div id="modal" />
    <input v-if="showSidebar" type="checkbox" id="sidebar-show-toggle" />

    <label v-if="showSidebar" for="sidebar-show-toggle"></label>

    <sidebar
      v-if="showSidebar"
      :structure="structure"
      @collapseSubmenu="collapseSubmenu"
      :submenuOpen="showSubMenu"
      :userHasRoles="userHasRoles"
    />

    <submenu
      v-if="userHasRoles && showSubMenu"
      :structure="structure"
      @collapseSubmenu="collapseSubmenu"
      class="sidebar-right"
    />

    <portal-target name="filter-sidebar-pages" slim />

    <portal-target name="filter-sidebar-sports" slim />

    <portal-target name="filter-sidebar-sports-provider" slim />

    <input v-if="showNavbar" type="checkbox" id="navbar-show-toggle" />

    <label v-if="showNavbar" for="navbar-show-toggle"></label>

    <navbar v-if="showNavbar" />
    <router-view class="container-fluid" :key="$route.fullPath" />
    <notifications />

    <DialogWrapper wrapper-name="default" />
    <preview-image-modal :previewImage="previewImage" v-if="previewImage" />

    <button
      v-if="showScrollToTopButton"
      @click="scrollToTop"
      class="btn btn-warning icon-button rounded-pill scroll-to-top-button animate fn-fade-in"
    >
      <i class="uil uil-angle-up"></i>
    </button>
  </div>
</template>

<style lang="scss">
@import '../node_modules/flatpickr/dist/flatpickr.css';
@import 'styles/main';
@import 'styles/components/scroll-top';
@import './components/fields/tiptap-plugins/anchor.css';
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<script>
import Sidebar from './components/Sidebar.vue'
import Submenu from './components/Submenu.vue'
import Navbar from './components/Navbar.vue'
import Search from './components/Search.vue'
import Notifications from './components/notifications.vue'
import NewVersionNotification from '@atoms/new-version-notification.vue'
import PreviewImageModal from './components/atomic/atoms/misc/preview-image-modal.vue'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import _ from 'lodash'
import { DialogWrapper } from 'vue3-promise-dialog'
import hotkeys from 'hotkeys-js'

export default {
  components: {
    Sidebar,
    Submenu,
    Navbar,
    Search,
    Notifications,
    NewVersionNotification,
    DialogWrapper,
    PreviewImageModal,
  },
  data: function () {
    return {
      showSidebar: true,
      showSubMenu: true,
      showNavbar: true,
      showSearch: false,
      showSidebarFilter: false,
      scrolledDown: false,
      scrollPosition: 0,
    }
  },
  created() {
    let collapse = JSON.parse(localStorage.getItem('collapseSubmenu'))
    this.showSubMenu = collapse
    this.handleScroll = _.throttle(this.handleScroll, 500)
  },
  computed: {
    structure: {
      get() {
        const filteredStructure = this.$store.state.sidebar.structure.filter((parent) => {
          if (['Home', 'Search'].includes(parent.name)) {
            return true
          }
          return parent?.children?.find((child) => {
            // parent title of children (e.g. SEO for Site Managament)
            if (child.subsection) {
              return false
            }
            return this.hasPermission(child.permission)
          })
        })
        // change the order of the returned sidebar structure
        return filteredStructure
      },
    },
    userHasRoles() {
      return this.$auth.check() ? this.$auth.user().roles.length > 0 : false
    },
    showScrollToTopButton() {
      return this.scrollPosition > 150 && this.scrolledDown
    },
    previewImage() {
      return this.$store.state.app.previewImage
    },
  },

  mounted() {
    this.$cookies.set('formIsDirty', false)
    // eslint-disable-next-line no-undef
    this.$store.dispatch('app/setEnvironmentName', process.env.VUE_APP_ENV)
    this.$store.dispatch('app/setEnvironmentColor', this.getEnvironmentColor())
    this.$store.dispatch('sidebar/getFavorites')
    window.addEventListener('scroll', this.handleScroll)

    hotkeys('/', () => {
      this.showSearch = !this.showSearch
    })
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    $route(to) {
      // Main Sidebar and Navbar
      if (to.path === '/login') {
        this.showSidebar = false
        this.showSubMenu = false
        this.showNavbar = false
      } else if (to.path === '/maintenance') {
        this.showSidebar = false
        this.showSubMenu = false
        this.showNavbar = true
      } else {
        this.showSidebar = true
        this.showNavbar = true
      }
    },
  },
  methods: {
    allowedToShow(child) {
      if (this.hasPermission(child.permission)) {
        return true
      }
    },
    collapseSubmenu(value) {
      localStorage.setItem('collapseSubmenu', value)
      this.showSubMenu = value
    },
    getEnvironmentColor() {
      const env = this.$store.state.app.environmentName
      if (env === 'local') {
        return '#808080'
      } else if (env === 'dev') {
        return '#27B300'
      } else if (env === 'staging') {
        return '#FF0037'
      } else if (env === 'experiment') {
        return '#E038EE'
      } else if (env === 'experiment2') {
        return '#3c5d19'
      } else if (env === 'imports') {
        return '#009fff'
      } else if (env === 'qa-automation') {
        return '#af1e75'
      } else {
        return '#0037ff'
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleScroll() {
      this.scrollPosition = window.scrollY
      this.scrolledDown = window.scrollY > 0
    },
  },
}
</script>
