export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        slug_field: 'short_name'
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short Name',
        type: 'slug',
        disabled: true
      },
      {
        name: 'standardised_logo_asset_id',
        required: false,
        display_name: 'Standardised Logo',
        help_text:
          'Standardised logos must be requested from the design team and uploaded by a Hercules admin',
        type: 'base_media',
        substitute: 'image',
        permission: 'misc.operators/manage-standardised-logos',
        standardised: true
      },
      {
        name: 'games_amount',
        required: true,
        display_name: 'Games Amount',
        type: 'text'
      },
      {
        name: 'founded',
        required: true,
        display_name: 'Founded',
        type: 'date'
      },
      {
        name: 'url',
        required: false,
        display_name: 'URL',
        type: 'text'
      },
      {
        name: 'support_email',
        required: false,
        display_name: 'Support Email',
        type: 'text'
      },
      {
        name: 'gambling_company_id',
        search_url: 'hercules/operators/gambling-companies',
        required: false,
        display_name: 'Owner',
        type: 'list-one',
        value_key: 'gamblingCompany.name',
        trackBy: 'id',
        selectLabel: 'name'
      },
      {
        name: 'affiliate_program_id',
        search_url: 'hercules/operators/affiliate-programs',
        required: false,
        display_name: 'Affiliate Program',
        type: 'list-one',
        value_key: 'affiliateProgram.name',
        trackBy: 'id',
        selectLabel: 'name'
      },
      {
        name: 'game_types',
        search_url: 'hercules/operators/game-types',
        required: true,
        display_name: 'Operator Types',
        display_field: 'name',
        type: 'list-many',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'currencies',
        required: false,
        search_url: 'hercules/globals/currencies?orderBy=name&ascending=1&with_no_assets=1',
        display_name: 'Currencies',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        help_text: 'Inactive currencies are shown in grey.'
      },
      {
        name: 'software',
        required: false,
        search_url: 'hercules/globals/software?with_no_assets=1',
        display_name: 'Providers',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'game_categories',
        search_url: 'hercules/globals/game-categories',
        display_name: 'Game Categories',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'selling_points',
        search_url: 'hercules/globals/selling-points',
        display_name: 'Selling Points',
        display_field: 'type',
        selectLabel: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'support_types',
        search_url: 'hercules/globals/support-types',
        display_name: 'Support Types',
        display_field: 'type',
        selectLabel: 'type',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'countries',
        required: false,
        search_url:
          'hercules/globals/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        display_name: 'Countries',
        display_field: 'name',
        type: 'list-many-nested-checkbox',
        fetch_on_load: true,
        validate_changes: true,
        help_text: 'Inactive countries are shown in grey.'
      },
      {
        name: 'restricted_countries',
        search_url:
          'hercules/globals/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        display_field: 'name',
        type: 'list-many-nested-checkbox',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'blacklisted_countries',
        search_url:
          'hercules/globals/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        display_name: 'Blacklisted Countries',
        display_field: 'name',
        type: 'list-many-nested-checkbox',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'restricted_vip_countries',
        search_url:
          'hercules/globals/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        display_name: 'VIP Restricted Countries',
        display_field: 'name',
        type: 'list-many-nested-checkbox',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'languages',
        search_url: 'hercules/globals/languages',
        display_name: 'Languages',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'betting_apps',
        display_name: 'Betting Apps',
        selectLabel: 'name',
        display_field: 'name',
        type: 'list-many',
        options: [
          { id: "IOS", name: 'IOS' },
          { id: "Android", name: 'Android' },
        ],
        required: false
      },
      {
        name: 'deposit_limits',
        required: false,
        type: 'checkbox',
        default: 0
      },
      {
        name: 'wager_limits',
        required: false,
        type: 'checkbox',
        default: 0
      },
      {
        name: 'session_limits',
        required: false,
        type: 'checkbox',
        default: 0
      },
      {
        name: 'loss_limits',
        required: false,
        type: 'checkbox',
        default: 0
      },
      {
        name: 'self_exclusion',
        required: false,
        type: 'checkbox',
        default: 0
      },
      {
        name: 'reality_check',
        required: false,
        type: 'checkbox',
        default: 0
      },
      {
        name: 'withdrawal_lock',
        required: false,
        type: 'checkbox',
        default: 0
      }
    ]
  },
  getConfig() {
    return {
      url: 'operators/operators',
      name: 'Operator',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  },
  getSportsBookFields() {
    return [
      {
        name: 'bet_builder',
        type: 'radio',
        help_text: 'Sportsbook field',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' }
        ]
      },
      {
        name: 'live_streaming',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' }
        ]
      },
      {
        name: 'cash_out',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' }
        ]
      },
      {
        name: 'in_play_betting',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' }
        ]
      },
      {
        name: 'acca_insurance',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' }
        ]
      },
      {
        name: 'sport_categories',
        search_url: 'hercules/operators/sport-categories',
        display_name: 'Sport Categories',
        type: 'list-many',
        fetch_on_load: false
      },
      {
        name: 'betting_types',
        search_url: 'hercules/operators/betting-types',
        display_name: 'Betting Types',
        type: 'list-many',
        fetch_on_load: false
      },
      {
        name: 'odds_types',
        display_name: 'Odds Type',
        selectLabel: 'name',
        display_field: 'name',
        type: 'list-many',
        options: [
          { id: "Fractional odds", name: 'Fractional odds' },
          { id: "Decimal odds", name: 'Decimal odds' },
          { id: "American odds", name: 'American odds' }
        ]
      }
    ]
  }
}
