<template>
  <div class="card">
    <dynamic-form
      ref="form"
      :inCard="true"
      form-type="edit"
      :formFields="structure.list"
      :path="config.url"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :on-remove="remove"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@atoms/misc/confirm-dialog'
import DynamicForm from '@/components/fields/DynamicForm'
import DepositMethod from '@models/DepositMethod'
import DepositMethodsService from '@services/DepositMethodsService'
import { openDialog } from 'vue3-promise-dialog'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  name: 'edit',
  components: { ConfirmDialog, DynamicForm },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: DepositMethod.getConfig(),
    }
  },
  mounted() {
    this.getDepositMethod()
  },
  methods: {
    getDepositMethod() {
      DepositMethodsService.getOne(this.$route.params['id'])
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Payment method not found') >= 0) {
                this.$router.push('/deposit-methods')
              }
            })
          } else {
            this.object = response.data.result[0]
            this.setTitle(`${this.object.name}: General`)
            this.getStructure()
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    getStructure() {
      this.structure = DepositMethod.getStructure()
    },
    async submit() {
      await DepositMethodsService.update(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.object = response.data.result[0]
            this.showSuccessMessage(`Payment method updated`)
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        DepositMethodsService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/deposit-methods')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>

<style scoped></style>
