<template>
  <div>
    <data-table
      model="koala/KoalaCampaign"
      service-file="koala/KoalaCampaignsService"
      path="koala/v1/campaign-tags"
      permissionPath="koala/v1/campaigns"
      modalSizeClass="modal-lg"
      :stateful-columns="true"
      :editGridLayout="true"
      :centered-modal="true"
      :editFieldFilters="['created', 'modified']"
      :searchDelay="600"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      @bulkClickByIds="bulkClickByIds"
      :enableBulkSelect="true"
      :enableQuickSearch="false"
      :export-all-rows="false"
      ref="table"
      :tableLimits="[10, 50, 100]"
    />

    <dynamic-bulk-edit
      v-if="showBulkEdit"
      ref="bulkEdit"
      model="koala/KoalaCampaign"
      service-file="koala/KoalaCampaignsService"
      @closed="() => (this.showBulkEdit = false)"
      @refresh="$refs.table.search()"
    />
  </div>
</template>

<script>
import DynamicBulkEdit from '@molecules/koala/dynamic-bulk-edit.vue'
import FilterChangedLogic from '@mixins/FilterChangedLogic'
export default {
  mixins: [FilterChangedLogic],
  data() {
    return {
      showBulkEdit: false,
    }
  },
  created() {
    this.setTitle()
  },
  components: { DynamicBulkEdit },
  methods: {
    mounted() {
      this.$refs.bulkEdit.show()
    },
    columns() {
      return [
        {
          field: 'business_unit_name',
          type: 'text',
          class: 'col-business_units',
          search_key: 'business_units',
          advancedFilter: true,
          path: 'koala/v1/business-units?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'campaign_name',
          type: 'text',
          search_key: 'campaign_name',
          filterable: true,
          advancedFilter: true,
          basicFilterType: 'text',
          showEmptyNull: false,
          showModalAdvanced: true,
          width: '150px',
        },
        {
          field: 'platform_name',
          type: 'text',
          class: 'col-platforms',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          order_key: 'platform_name',
          searchBy: 'name',
        },
        {
          field: 'operator_name',
          type: 'text',
          filterable: true,
          order_key: 'operator_name',
          class: 'col-operators',
          search_key: 'operators',
          advancedFilter: true,
          path: this.computedPath.operatorsPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'account_username',
          type: 'text',
          class: 'col-accounts',
          search_key: 'accounts',
          advancedFilter: true,
          path: this.computedPath.accountsPath,
          optionLabelKey: 'username',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'username',
          order_key: 'account_username',
          extraSelectLabel: 'operator.name',
          width: '300px',
        },
        {
          label: 'Account Manager',
          field: 'account_manager_name',
          type: 'text',
          class: 'col-account_manager_name',
          search_key: 'account_manager_name',
          advancedFilter: true,
          path: 'backoffice/users/byRole?roles=accounts,affiliate management&orderBy=name&ascending=1',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'name',
          showAllOption: true,
          searchBy: 'name',
          width: '300px',
        },
        {
          label: 'Report',
          field: 'report_name',
          fieldCustomName: 'Report name',
          type: 'text',
          sortable: true,
          filterable: true,
          class: 'col-report-name',
          search_key: 'reports',
          advancedFilter: true,
          path: this.computedPath.reportsPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          order_key: 'report_name',
          trackBy: 'id',
          showAllOption: true,
          extraSelectLabel: 'platform.name',
          width: '300px',
          searchBy: 'name',
        },
        {
          field: 'brand_name',
          type: 'text',
          class: 'col-brands',
          search_key: 'brands',
          advancedFilter: true,
          path: 'koala/v1/tags/brands?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'unit_name',
          type: 'text',
          class: 'col-units',
          search_key: 'units',
          advancedFilter: true,
          path: 'koala/v1/tags/units?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'vertical_name',
          type: 'text',
          class: 'col-verticals',
          search_key: 'verticals',
          advancedFilter: true,
          path: 'koala/v1/tags/verticals?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'channel_name',
          type: 'text',
          class: 'col-channels',
          search_key: 'channels',
          advancedFilter: true,
          path: 'koala/v1/tags/channels?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'country_name',
          type: 'text',
          class: 'col-countries',
          search_key: 'countries',
          advancedFilter: true,
          path: 'koala/v1/tags/countries?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'source_name',
          type: 'text',
          class: 'col-sources',
          search_key: 'sources',
          advancedFilter: true,
          path: 'koala/v1/tags/sources?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'website_name',
          type: 'text',
          class: 'col-websites',
          search_key: 'websites',
          advancedFilter: true,
          path: 'koala/v1/tags/websites?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'page_type_name',
          type: 'text',
          class: 'col-page_types',
          search_key: 'page_types',
          advancedFilter: true,
          path: 'koala/v1/tags/page-types?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'position_name',
          type: 'text',
          class: 'col-positions',
          search_key: 'positions',
          advancedFilter: true,
          path: 'koala/v1/tags/positions?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'device_name',
          label: 'Device',
          type: 'text',
          class: 'col-devices',
          search_key: 'devices',
          advancedFilter: true,
          path: 'koala/v1/tags/devices?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
          width: '200px',
        },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          business_unit_name: function (row) {
            return row.business_unit_name
          },
        },
        {
          platform_name: function (row) {
            return row.platform_name
          },
        },
        {
          operator_name: function (row) {
            return row.operator_name
          },
        },
        {
          account_username: function (row) {
            return row.account_username
          },
        },
        {
          account_manager_name: function (row) {
            return row.account_manager_name
          },
        },
        {
          report_name: function (row) {
            return row.report_name
          },
        },
        {
          position_name: function (row) {
            return row.position_name
          },
        },
        {
          report_name: function (row) {
            return row.report_name
          },
        },
        {
          brand_name: function (row) {
            return row.brand_name
          },
        },
        {
          unit_name: function (row) {
            return row.unit_name
          },
        },
        {
          vertical_name: function (row) {
            return row.vertical_name
          },
        },
        {
          channel_name: function (row) {
            return row.channel_name
          },
        },
        {
          country_name: function (row) {
            return row.country_name
          },
        },
        {
          page_type_name: function (row) {
            return row.page_type_name
          },
        },
        {
          device_name: function (row) {
            return row.device_name
          },
        },
        {
          website_name: function (row) {
            return row.website_name
          },
        }
      )
    },
    bulkClickByIds(ids, extraData , filters, resultCount) {
      if (filters && Object.keys(filters).length > 0) {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
      } else {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByIds(ids))
      }
    },
    bulkClickByFilters(ids, filters, resultCount, extraData) {
      this.showBulkEdit = true
      this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
    },
    },
}
</script>
